import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import Ring from '@bit/joshk.react-spinners-css.ring'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import Multiselect from '../Multiselect'
import Delete from './delete'

import { isValidUrl, sendRequest, parseTitle } from '../../helpers'
import { tagsState, entriesState, entryState, userState, responseSelector } from '../../atoms'
import { useOverlay } from '../../hooks'
import { AFTER_LOGIN_ACTION_KEY } from '../../constants'

import {
  FormContainer, InputsContainer, InputContainer,
  ActionContainer, TextInput, Label, SubmitButton, Cancel
} from '../Form'

const UrlContainer = styled(InputContainer)`
  position: relative;
`

const Loader = styled(Ring)`
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  right: 15px;
  top: 13px;
`

const UrlInput = styled(TextInput)`
  padding: 14px 45px 14px 14px;
`

const EntryForm = () => {
  const [tags] = useRecoilState(tagsState)
  const [entries] = useRecoilState(entriesState)
  const [entry, setEntry] = useRecoilState(entryState)
  const currentUser = useRecoilValue(userState)
  const [open, close] = useOverlay()
  const setResponse = useSetRecoilState(responseSelector)
  const { handleSubmit, register, setValue, formState: { errors }  } = useForm({ entry })
  register('tags', { required: 'At least one tag is required.'})

  const isEditing = !!entry.id
  const isWebhook = !!entry.webhook

  const [titleFetched, setTitleFetched] = useState((isEditing || isWebhook))
  const [url, setUrl] = useState(entry.url)
  const [title, setTitle] = useState(entry.title)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const values = Object.values(entries)

    if (!isValidUrl(url)) { return }

    const existing = values.filter(e => e.url === url)[0]

    if (existing) {
      setEntry(existing)
      setTitle(existing.title)
      setValue('tags', existing.tags)
      setTitleFetched(true)
      setIsLoading(false)
    } else if (!titleFetched) {
      getTitle(url)
    }
  }, [url, entries, titleFetched, setEntry, setValue])

  const getTitle = (url) => {
    setIsLoading(true)
    return fetch(process.env.REACT_APP_CORS_PROXY + url)
      .then(response => response.text())
      .then(html => {
        const doc = new DOMParser().parseFromString(html, "text/html")
        const title = doc.querySelectorAll('title')[0]
        setTitle(parseTitle(title.innerText))
        setTitleFetched(true)
        setIsLoading(false)
      })
      .catch(error => {
        setTitleFetched(true)
        setIsLoading(false)
      })
  }

  const handleTags = (_tag, tags) => {
    setValue('tags', tags)
  }

  const onSubmit = (data) => {
    setIsSubmitting(true)

    if (currentUser.loggedIn) {
      submitEntry(data)
    } else {
      setAfterLogin(data)
    }
  }

  const setAfterLogin = (data) => {
    localStorage.setItem(AFTER_LOGIN_ACTION_KEY, JSON.stringify(data))
    open({ type: 'signUp' })
  }

  const submitEntry = (data) => {
    // TODO ugh this whole function
    // especially `handleEdit and handleNew
    // all this shit seems like a smell that
    // would probably be fixed by:
    // - figuring out a data better data struture
    // - probably have a more sustainable state solution (recoil?)

    let url = 'entries'
    if (isEditing) { url = url + '/' + entry.id }
    sendRequest(url, (isEditing ? 'PUT' : 'POST'), data)
      .then(response => {
        setIsSubmitting(false)
        setResponse(response)
        close()
        if (isWebhook) {
          window.location.href = data.url
        }
      })
  }

  const submitValue = () => {
    if (isSubmitting) {
      return 'Loading...'
    } else if (isEditing) {
      return 'Update'
    } else {
      return 'Add'
    }
  }

  return(
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {isEditing && <Delete entry={entry} close={close} />}
      <InputsContainer>
        <UrlContainer>
          <UrlInput
            {...register('url', { required: 'Url is Required.'})}
            type='url'
            name='url'
            placeholder='https://example.com/link'
            error={errors['url']}
            value={url || ''} onChange={(e) => setUrl(e.target.value)}
          />
          <Loader color='#353535' size={20} visible={isLoading} />
        </UrlContainer>
        {titleFetched &&
          <>
            <InputContainer>
              <Label htmlFor='title'>Title</Label>
              <TextInput
                {...register('title', { required: 'Title is Required.'})}
                type='text'
                name='title'
                placeholder='Link Title'
                error={errors['title']}
                value={title} onChange={(e) => setTitle(e.target.value)}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor='tags'>Tags</Label>
              <Multiselect
                defaultValues={entry.tags}
                options={Object.keys(tags)}
                onAdd={handleTags}
                onRemove={handleTags}
                error={errors['tags']}
              />
            </InputContainer>
          </>
        }
      </InputsContainer>
      <ActionContainer>
        <SubmitButton as='input' type='submit' value={submitValue()} disabled={isSubmitting} />
        {titleFetched &&
          <Cancel onClick={close}>cancel</Cancel>
        }
      </ActionContainer>
    </FormContainer>
  )
}


export default EntryForm
