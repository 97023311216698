import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { RECOIL_PERSIST_KEY } from './constants'

const { persistAtom } = recoilPersist({
  key: RECOIL_PERSIST_KEY
})

export const entriesState = atom({
  key: 'entries',
  default: {},
})

export const tagsState = atom({
  key: 'tags',
  default: {},
})

export const overlayState = atom({
  key: 'overlay',
  default: null,
})

export const loadedState = atom({
  key: 'loaded',
  default: false,
})

export const entryState = atom({
  key: 'entry',
  default: {
    url: null,
    title: null,
    tags: [],
  },
})

export const userState = atom({
  key: 'user',
  default: {
    id: null,
    loggedIn: false,
    token: null,
  },
  effects_UNSTABLE: [persistAtom],
})

export const responseSelector = selector({
  key: 'responseSelector',
  set: ({ get, set }, array) => {

    const entries = array.reduce((acc, val) => (
      {...acc, [val.id]: { ...val, tags: val.tags.map(t => t.name) } }
    ), {})

    let tags = {}

    array.forEach(item => {
      item.tags.forEach(tag => {
        const entryIds = (tags[tag.name] && tags[tag.name].entries) || []
        tags = { ...tags, [tag.name]: {
          id: tag.id,
          name: tag.name,
          entries: [...entryIds, item.id ]
        }}
      })
    })

    set(entriesState, entries)
    set(tagsState, tags)
  }
})