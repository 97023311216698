import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import logo from '../../images/logo.png'

import { sendRequest } from '../../helpers'
import { responseSelector, entriesState, tagsState } from '../../atoms'

const Title  = styled.h1`
  font-weight: bold;
  position: relative;
  margin: 15px;
  text-transform: capitalize;

  a {
    text-decoration: none;
  }
`

const Logo = styled.a`
  content: '';
  position: absolute;
  margin: -3px 0 0 3px;
  background-image url(${logo});
  width: 16px; height: 16px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-size: 0;
`

const EntryList = styled.ul`
  margin: 15px;
`

const EntryListItem = styled.li`
  margin-bottom: 5px;
`

const EntryLink = styled.a`

`


const Tag = () => {
  const { uuid } = useParams()
  const entries = useRecoilValue(entriesState)
  const tags = useRecoilValue(tagsState)
  const tag = Object.values(tags)[0]
  const setResponse = useSetRecoilState(responseSelector)

  useEffect(() => {
    sendRequest(`tags/${uuid}`)
    .then(response => setResponse(response))
  }, [setResponse, uuid])

  if (!tag) { return null}

  return <>
    <Title>
      <a href='/'>{tag.name} links</a>
      <Logo href='/'>Save to Linkhorse</Logo>
    </Title>
    <EntryList>
      {Object.values(entries).map((entry, index) => (
        <EntryListItem key={index}>
          <EntryLink href={entry.url}>{entry.title}</EntryLink>
        </EntryListItem>
      ))}
    </EntryList>
  </>
}

export default Tag