import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { useOverlay } from '../../hooks'
import Button from '../Styles/button'

const width = '600px'

export const FormContainer = styled.form`
  padding: 15px;
`

export const InputsContainer = styled.div`
  float: left;
  width: 75%;

  @media (max-width: ${width}) {
    width: 100%;
    float: none;
  }
`

export const InputContainer = styled.div`
  margin-bottom: 15px;
`

export const TextInput = styled.input`
  -webkit-appearance: none;
  font-family: 'Cera Pro', sans-serif;
  border-radius: 4px;
  padding: 14px;
  display: block;
  width: 100%;
  border: 1px solid ${props => (props.error ? `indianred` : `#ccc`)};
  outline: none;
`

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`

export const ActionContainer = styled.div`
  float: left;
  margin-left: 5%;
  width: 20%;

  @media (max-width: ${width}) {
    width: 100%;
    float: none;
    margin-left: 0;
    overflow: hidden;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;

  @media (max-width: ${width}) {
    width: 115px;
    float: right;
  }
`

export const Cancel = styled.h5`
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  margin: 15px 0 0;

  @media (max-width: ${width}) {
    float: right;
    margin: 15px 30px 0 0;
  }
`

const Error = styled.div`
  margin-bottom: 5px;
  color: indianred;
`

export function Form({ defaultValues, children, onSubmit, submitText, showCancel }) {
  const { handleSubmit, register, formState: { errors }  } = useForm({ defaultValues })
  const close = useOverlay()[1]

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <InputsContainer>
        {React.Children.map(children, child => {
          const { name, label } = child.props

          if (name) {
            return <InputContainer>
              {label && !errors[name] && <Label htmlFor={name}>{label}</Label>}
              {errors[name] && <Error>{errors[name].message}</Error>}
              {React.createElement(child.type, { ...child.props, register: register, errors: errors, key: name })}
            </InputContainer>
          } else {
            return child
          }
        })}
      </InputsContainer>
      <ActionContainer>
        <SubmitButton as='input' type='submit' value={submitText} />
        {showCancel &&
          <Cancel onClick={close}>cancel</Cancel>
        }
      </ActionContainer>
    </FormContainer>
  )
}

export const Input = ({ register, errors, name, rules, ...rest }) => (
  <TextInput
    {...register(name, rules)}
    error={errors[name]}
    {...rest}
  />
)