
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import jwt_decode from 'jwt-decode'
import { overlayState, entryState, userState } from './atoms'

export function useOverlay() {
  const setOverlay = useSetRecoilState(overlayState)
  const setEntry = useSetRecoilState(entryState)
  const removeOverlay = useResetRecoilState(overlayState)
  const removeEntry = useResetRecoilState(entryState)

  function open(content) {
    setOverlay(content.type)
    if (content.data) {
      setEntry(content.data)
    } else {
      removeEntry()
    }
  }

  function close() {
    removeOverlay()
    removeEntry()
  }

  return [open, close]
}

export function useUser() {
  const setUser = useSetRecoilState(userState)
  const removeUser = useResetRecoilState(userState)

  function logIn(payload) {
    const user = jwt_decode(payload.token)
    setUser({ ...user, loggedIn: true, token: payload.token })
    window.location.reload()
  }

  function logOut() {
    removeUser()
    window.location.reload()
  }

  return [logIn, logOut]
}