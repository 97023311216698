import React, { useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import { SIDEBAR_WIDTH } from '../../constants'

import { useOverlay, useUser } from '../../hooks'
import { userState } from '../../atoms'

const Container = styled.div`
  position: fixed;
  padding: 15px;
  margin-left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
`
const Menu = styled.h3`
  position: absolute;
  right: 15px;
  text-align: right;
  cursor: pointer;
  padding: 15px;
  margin: -15px;
  background-color: #f7f7f7;
`

const Target = styled.div`
`

const NavContainer = styled.nav`
  margin-top: 15px;
`

const MenuItem = styled.li`
  margin-bottom: 5px;
`

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  return <Container>
    <Menu>
      <Target onClick={() => setIsOpen(!isOpen)}>Menu</Target>
      {isOpen && <Nav />}
    </Menu>
  </Container>
}

const Nav = () => {
  const [open] = useOverlay()
  const currentUser = useRecoilValue(userState)
  const logOut = useUser()[1]

  return <NavContainer>
    <ul>
      <MenuItem>
        <button onClick={() => open({ type: 'bookmarklet' })}>Bookmarklet</button>
      </MenuItem>
      {!currentUser.loggedIn && <>
        <MenuItem>
          <button onClick={() => open({ type: 'logIn' })}>Log In</button>
        </MenuItem>
        <MenuItem>
          <button onClick={() => open({ type: 'signUp' })}>Sign Up</button>
        </MenuItem>
      </>}
      {currentUser.loggedIn && <>
        <MenuItem>
          <button onClick={() => open({ type: 'settings' })}>Settings</button>
        </MenuItem>
        <MenuItem>
          <button onClick={logOut}>Log Out</button>
        </MenuItem>
      </>}

    </ul>
  </NavContainer>
}

export default Header