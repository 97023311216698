import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import { overlayState } from '../../atoms'
import { useOverlay } from '../../hooks'

import EntryForm from '../EntryForm'
import LogIn from '../Auth/logIn'
import SignUp from '../Auth/signUp'
import Settings from '../Auth/settings'
import Bookmarklet from '../Bookmarklet'

import { OVERLAY_WIDTH } from '../../constants'

const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const OverlayArea = styled.div`
  background-color: #f7f7f7;
  width: ${OVERLAY_WIDTH};
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;

  @media (max-width: ${OVERLAY_WIDTH}) {
    width: 98%;
  }
`

const Header = styled.div`
  padding: 15px;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
`

const Title = styled.h3`
  font-weight: bold;
  float: left;
  position: relative;
  top: 3px;
`

const Close = styled.h4`
  float: right;
  font-size: 18px;
  cursor: pointer;
`

const Overlay = ({ children }) => {
  const overlay = useRecoilValue(overlayState)
  const close = useOverlay()[1]

  const handleKeyDown = (event) => {
    if(event.keyCode === 27) {
      close()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const component = () => {
    switch (overlay) {
      case 'logIn':
        return <LogIn />
      case 'signUp':
        return <SignUp />
      case 'settings':
        return <Settings />
      case 'bookmarklet':
        return <Bookmarklet />
      default:
        return <EntryForm />
    }
  }

  const title = () => {
    switch (overlay) {
      case 'editEntry':
        return 'Edit Entry'
      case 'logIn':
        return 'Log In to your Linkhorse account'
      case 'signUp':
        return 'Sign Up for a Linkhorse account'
      case 'settings':
        return 'Account Settings'
      case 'bookmarklet':
        return 'Bookmarklet'
      default:
        return 'Add a Link'
    }
  }

  return !overlay ? null : <OverlayContent component={component} title={title} />
}

const OverlayContent = ({ title, component }) => {
  const close = useOverlay()[1]

  const handleClick = (event) => {
    if (event.currentTarget && event.target !== event.currentTarget) { return }
    close()
  }

  return <OverlayContainer onClick={handleClick}>
    <OverlayArea>
      <Header>
        <Title>{title()}</Title>
        <Close onClick={handleClick}>×</Close>
      </Header>
      {component()}
    </OverlayArea>
  </OverlayContainer>
}

export default Overlay