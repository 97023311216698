import React from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import logo from '../../images/logo.png'

import TagList from '../TagList'
import Button from '../Styles/button'

import { MEDIA_QUERY_WIDTH } from '../../constants'
import { tagsState } from '../../atoms'
import { useOverlay } from '../../hooks'

const Container = styled.div`
  width: 200px;
  position: fixed;
  top: 0; bottom: 0; left: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    flex-direction: unset;
    display: block;
    background: #f7f7f7;
    bottom: auto;
    width: 100%;
  }
`

const Title  = styled.h1`
  font-weight: bold;
  position: relative;
  margin: 15px 15px 0;

  a {
    text-decoration: none;
  }
`

const Logo = styled.a`
  content: '';
  position: absolute;
  margin: -3px 0 0 3px;
  background-image url(${logo});
  width: 16px; height: 16px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-size: 0;
`

const SidebarButton = styled(Button)`
  margin: 15px 10px;

  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    display: block;
  }
`

const Sidebar = () => {
  const tags = useRecoilValue(tagsState)
  const [open] = useOverlay()

  return(
    <Container>
      <Title>
        <a href='/'>Linkhorse</a>
        <Logo onClick={() => open({ type: 'bookmarklet' })}>Save to Linkhorse</Logo>
      </Title>
      <SidebarButton onClick={() => open({ type: 'newEntry' })}>+ Add Link</SidebarButton>
      <TagList tags={tags} />
    </Container>
  )
}

export default Sidebar