import React from 'react'
import styled from 'styled-components'
import { useSetRecoilState } from 'recoil'

import { sendRequest } from '../../helpers'
import { responseSelector } from '../../atoms'

const DeleteSpan = styled.span`
  color: #f3780c;
  margin: 90px 0 0;
  text-decoration: underline;
  font-weight: normal;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: -71px;
  z-index: 2;
  left: 85px;
`

const Delete = ({ entry, close }) => {
  const setResponse = useSetRecoilState(responseSelector)

  const deleteEntry = (e) => {
    sendRequest(`entries/${entry.id}`, 'DELETE')
    .then(response => setResponse(response))

    close(e)
  }

  return(
    <DeleteSpan onClick={deleteEntry}>delete</DeleteSpan>
  )
}

export default Delete