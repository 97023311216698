import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'

const SelectContainer = styled.div`
  margin-bottom: 15px;
`

const InputContainer = styled.div`
  padding: 9px 15px 7px;
  border-radius: 4px;
  border: 1px solid ${props => (props.error ? `indianred` : `#ccc`)};
  outline: none;
  background-color: white;
`

const ValuesList = styled.ul`
  display: inline-block;
`

const ValueItem = styled.li`
  background: #353535;
  color: #f7f7f7;
  padding: 7px;
  display: inline-block;
  margin: 0 3px 3px 0;

  :after {
    content: '×';
    margin-left: 8px;
    position: relative;
    top: 2px;
    font-size: 18px;
    line-height: 11px;
    font-weight: bold;
    cursor: pointer;
  }
`

const Input = styled.input`
  font-family: 'Cera Pro', sans-serif;
  border: none;
  outline: none;
  display: inline-block;
  padding: 6px 6px 5px;
  margin: 0 3px 3px 0;
`

const AutocompleteContainer = styled.div`
  position: relative;
  top: 1px;
`

const AutocompleteList = styled.ul`
  position: absolute;
  background: white;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #ccc;
  width: 100%;
`

const hoverStyle = css`
  color: #f7f7f7;
  background: #353535;
`

const AutocompleteOption = styled.li`
  padding: 7px;
  cursor: pointer;

  ${props => props.selected && css`
    ${hoverStyle}
  `}

`

const Strong = styled.strong`
  font-weight: bold;
`

const Multiselect = ({ defaultValues, options, onAdd, onRemove, error }) => {
  const inputEl = useRef()

  const OPTION_LIMIT = 5

  const [values, setValues] = useState(defaultValues)
  const [value, setValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const [newValue, setNewValue] = useState(null)
  const [optionIndex, setOptionIndex] = useState(0)

  const setup = () => {
    if (values.length < 1 && inputEl.current === document.activeElement) {
      const firstFew = options.slice(0, 5)
      setFilteredOptions(firstFew)
    } else {
      setFilteredOptions([])
    }
  }

  const filterOptions = () => {
    if (!!value) {
      const filtered = options.filter(option => option.match(value.toLowerCase()))
      const removeSelected = filtered.filter(option => !values.includes(option))
      const limited = removeSelected.slice(0, OPTION_LIMIT)
      setFilteredOptions(limited)
    } else {
      setFilteredOptions([])
    }
  }

  const handleNewValue = () => {
    const downcase = value.toLowerCase()
    if (options.includes(downcase) || values.includes(downcase)) {
      setNewValue(null)
    } else {
      setNewValue(value)
    }
  }

  const updateDefaults = () => {
    setValues(defaultValues)
  }

  useEffect(setup, [values])
  useEffect(filterOptions, [value])
  useEffect(handleNewValue, [value])
  useEffect(updateDefaults, [defaultValues])

  const addValue = (value) => {
    const newValues = [...values, value]
    setValues(newValues)
    onAdd(value, newValues)
    setFilteredOptions([])
    setValue('')
    setCursor()
  }

  const setCursor = () => {
    inputEl.current.focus()
  }

  const remove = (toRemove) => {
    const newValues = values.filter(value => value !== toRemove)
    onRemove(toRemove, newValues)
    setValues(newValues)
  }

  const handleKeyDown = (event) => {
    const key = event.keyCode

    if (key === 8) {
      // backspace
      if (!event.target.value) {
        remove(values[values.length - 1])
      }
    } else if (key === 13) {
      // enter
      event.preventDefault()
      if (filteredOptions.length === optionIndex) {
        // means that "new tag" is selected
        if (newValue) { addValue(newValue) }
      } else {
        addValue(filteredOptions[optionIndex])
      }
      setOptionIndex(0)
    } else if (key === 40) {
      // down arrow
      updateOptionIndex(1)
    } else if (key === 38) {
      // up arrow
      updateOptionIndex(-1)
    }
  }

  const updateOptionIndex = (diff) => {
    let newIndex = optionIndex + diff
    const modifier = !!newValue ? 0 : 1
    const max = filteredOptions.length - modifier

    if (newIndex > max) {
      newIndex = 0
    }

    if (newIndex < 0) {
      newIndex = max
    }

    setOptionIndex(newIndex)
  }


  return <SelectContainer>
    <InputContainer onClick={setCursor} error={error}>
      <ValuesList>
        {values.map((value, index) =>
          <ValueItem key={index} onClick={(e) => remove(e.target.innerHTML)}>
            {value}
          </ValueItem>
        )}
      </ValuesList>
      <Input
        type='text'
        name='value'
        placeholder='ex: book, movie, recipe, etc...'
        value={value} onChange={(e) => setValue(e.target.value)}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        ref={inputEl}
        onFocus={setup}
        onKeyDown={handleKeyDown}
      />
    </InputContainer>
    {(filteredOptions.length > 0 || newValue) &&
      <AutocompleteContainer>
        <AutocompleteList>
          {filteredOptions.map((filteredOption, index) =>
            <AutocompleteOption
              key={index}
              onClick={(e) => addValue(e.target.innerHTML)}
              selected={index === optionIndex}
              onMouseOver={() => setOptionIndex(index)}
            >{filteredOption}</AutocompleteOption>
          )}
          {newValue &&
            <AutocompleteOption
              onClick={() => addValue(newValue)}
              selected={filteredOptions.length === optionIndex}
              onMouseOver={() => setOptionIndex(filteredOptions.length)}
            >Add new tag <Strong>{newValue}</Strong></AutocompleteOption>
          }
        </AutocompleteList>
      </AutocompleteContainer>
    }
  </SelectContainer>
}

export default Multiselect
