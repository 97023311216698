import { RECOIL_PERSIST_KEY } from './constants'

export const isValidUrl = (string) => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

export async function sendRequest(path, method = 'GET', body = undefined) {
  const url = process.env.REACT_APP_API_URL + path

  const response = await fetch(url, {
    method: method,
    headers: setHeaders(),
    body: JSON.stringify(body),
  })

  if (response.status === 204) return '' // handle no content response
  const json = await response.json()

  return response.ok ? json : Promise.reject(json)
}

const setHeaders = () => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  const stored = localStorage.getItem(RECOIL_PERSIST_KEY)
  if (stored) {
    const { user: { token } } = JSON.parse(stored)
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
  }

  return headers
}

export const slugify = (string) => (
  string.toLowerCase().replace(' ', '-')
)

export const parseTitle = (text) => (
  text.replace(/\s+/g,' ').trim()
)
