import React from 'react'

import { Form, Input } from '../Form'
import AuthLinks from './links'

import { useUser } from '../../hooks'
import { sendRequest } from '../../helpers'

export default function SignUp() {
  const logIn = useUser()[0]

  const handleSubmit = (data) => {
    sendRequest('users', 'POST', data)
      .then(response => logIn(response))
  }

  return <Form onSubmit={handleSubmit} submitText='Sign Up' showCancel={true}>
    <Input
      type='email'
      name='email'
      label='Email Address'
      autoComplete='email'
      rules={{ required: 'Email is required.' }}
    />
    <Input
      type='password'
      name='password'
      label='Password'
      autoComplete='new-password'
      rules={{ required: 'Password is required.' }}
    />
    <Input
      type='password'
      name='password_confirmation'
      label='Confirm Password'
      autoComplete='new-password'
      rules={{ required: 'Password Confirmation is required.' }}
    />
    <AuthLinks current='signUp' />
  </Form>
}