import React from 'react'

import { Form, Input } from '../Form'
import AuthLinks from './links'

import { useUser } from '../../hooks'
import { sendRequest } from '../../helpers'

export default function LogIn() {
  const logIn = useUser()[0]

  const handleSubmit = (data) => {
    sendRequest('sessions', 'POST', data)
      .then(response => logIn(response))
  }

  return <Form onSubmit={handleSubmit} submitText='Log In' showCancel={true}>
    <Input
      type='email'
      name='email'
      label='Email Address'
      autoComplete='email'
      rules={{ required: 'Email is required.' }}
    />
    <Input
      type='password'
      name='password'
      label='Password'
      autoComplete='current-password'
      rules={{ required: 'Password is required.' }}
    />
    <AuthLinks current='logIn' />
  </Form>
}