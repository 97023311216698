import React, { useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import { slugify } from '../../helpers'
import { MEDIA_QUERY_WIDTH, SIDEBAR_WIDTH } from '../../constants'
import { tagsState, entriesState, userState, loadedState } from '../../atoms'
import { useOverlay } from '../../hooks'

const Container = styled.div`
  padding: 15px;
  margin-left: ${SIDEBAR_WIDTH};

  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    margin-left: 0;
    margin-top: 90px;
  }
`

const Explainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  display: inline-block;
  margin-bottom: 25px;

  p {
    margin-bottom: 10px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
`

const TagList = styled.div`
  margin-top: 25px;
`

const Tag = styled.section`
  margin-bottom: 50px;

  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    margin-bottom: ${props => props.isOpen ? '50px' : '15px'}
  }
`

const Anchor = styled.a`
  // this is from https://css-tricks.com/hash-tag-links-padding/
  // to give the anchor some padding from the top of the page
  :before {
    display: block;
    content: " ";
    margin-top: -15px;
    height: 15px;
    visibility: hidden;
    pointer-events: none;
  }
`

const Heading = styled.h2`
  margin-bottom: 15px;

  :hover a {
    display: inline;
  }
`

const Title = styled.span`
  font-weight: bold;

  text-transform: capitalize;
  margin-right: 5px;

  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    &:after {
      content: ${props => props.isOpen ? `"(${props.count}) ▲"` : `"(${props.count}) ▼"`};
      margin-left: 2px;
    }
  }
`

const Share = styled.a`
  color: #999;
  display: none;
  cursor: pointer;
  text-decoration: none;

  :hover {
    color: #353535;
  }
`

const List = styled.ul`
  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    display: ${props => props.isOpen ? 'block' : 'none'}
  }
`

const Item = styled.li`
  margin-bottom: 5px;

  :hover a {
    display: inline;
  }
`

const Edit = styled.a`
  color: #999;
  display: none;
  cursor: pointer;

  :hover {
    color: #353535;
  }
`

const Main = () => {
  const tags = useRecoilValue(tagsState)
  const currentUser = useRecoilValue(userState)
  const loaded = useRecoilValue(loadedState)
  const [open] = useOverlay()
  const tagArray = Object.values(tags)

  return <Container>
    {!currentUser.loggedIn && <Explainer>
      <p>Linkhorse is a simple way to organize your bookmarks. Below are some examples.</p>
      <p>
        <button onClick={() => open({ type: 'signUp' })}>Sign up</button>
        {' '} to start saving your bookmarks!
      </p>
    </Explainer>}
    {currentUser.loggedIn && tagArray.length < 3 && loaded && <Explainer>
      <p>Start adding links!</p>
      <p>
        You can also use our {' '}
        <button onClick={() => open({ type: 'bookmarklet' })}>bookmarklet</button>
        {' '} to make this easier!
      </p>
    </Explainer>}
    <TagList>{tagArray.map((tag, index) => <TagSection tag={tag} key={index} />)}</TagList>
  </Container>
}

const TagSection = ({ tag }) => {
  const entries = useRecoilValue(entriesState)
  const [isOpen, setIsOpen] = useState(false)

  return(
    <Tag isOpen={isOpen}>
      <Anchor name={slugify(tag.name)} />
      <Heading>
        <Title
          onClick={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          count={tag.entries.length}
        >{tag.name}</Title>
        <Share href={`/tags/${tag.id}`}>Share</Share>
      </Heading>
      <List isOpen={isOpen}>
        {tag.entries.map((entryName, index) => {
          return <EntryListItem key={index} entry={entries[entryName]} />
        })}
      </List>
    </Tag>
  )
}

const EntryListItem = ({ entry }) => {
  const [open] = useOverlay()

  return <Item>
    <a href={entry.url}>{entry.title}</a>
    {' '}
    <Edit onClick={() => open({ type: 'editEntry', data: entry })}>edit</Edit>
  </Item>
}

export default Main
