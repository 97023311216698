import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './stylesheets/reset.css'
import './stylesheets/fonts.css'
import './stylesheets/global.css'
import Home from './components/Home'
import Tag from './components/Tag'
import * as serviceWorker from './serviceWorker'

const NotFound = () => <h1>Sorry can't find this page</h1>

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path='tags/:uuid' element={<Tag />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
