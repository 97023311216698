import { useEffect } from 'react'
import { parseTitle } from '../../helpers'
import { useOverlay } from '../../hooks'

export default function Webhook() {
  const [open] = useOverlay()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const url = params.get('url')
    const title = params.get('title') ? parseTitle(params.get('title')) : undefined

    const entryParams = {
      url: url,
      title: title,
      webhook: true,
      tags: [],
    }

    if (url) {
      open({ type: 'newEntry', data: entryParams })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return null
}