import styled from 'styled-components'

export default styled.a`
  font-weight: bold;
  font-family: 'Cera Pro', sans-serif;
  padding: 16px 15px 14px;
  display: inline-block;
  background: #353535;
  color: #f7f7f7;
  border: none;
  cursor: pointer;

  border-radius: 4px;

  text-align: center;
  // prevent iOS styling
  -webkit-appearance: none;

  :disabled {
    background: #666666;
  }
`