import React from 'react'
import styled from 'styled-components'
import { useOverlay } from '../../hooks'

import { OVERLAY_WIDTH } from '../../constants'

const LinksList = styled.ul`
  margin: 25px 0;

  @media (max-width: ${OVERLAY_WIDTH}) {
    margin: 25px 0;
  }
`

const ListItem = styled.li`
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const links = [
  {
    link: 'signUp',
    url: 'sign-up',
    text: 'Don\'t have an account? Sign Up.'
  },
  {
    link: 'logIn',
    url: 'log-in',
    text: 'Already have an account? Log In.'
  },
  // {
  //   link: 'forgotPassword',
  //   text: 'Forgot your password?'
  // },
]

export default function AuthLinks({ current }) {
  const [open] = useOverlay()

  return <LinksList>
    {links.filter(l => l.link !== current).map(link =>
      <ListItem key={link.link}>
        <a href={link.url} onClick={(e) => {
          e.preventDefault()
          open({ type: link.link })
        }}>{link.text}</a>
      </ListItem>
    )}
  </LinksList>
}