import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { AFTER_LOGIN_ACTION_KEY } from '../../constants'
import { sendRequest } from '../../helpers'
import { userState, responseSelector } from '../../atoms'

export default function AfterLogin() {
  const currentUser = useRecoilValue(userState)
  const setResponse = useSetRecoilState(responseSelector)

  useEffect(() => {
    if (currentUser.loggedIn) {
      const action = localStorage.getItem(AFTER_LOGIN_ACTION_KEY)

      if (action) {
        const data = JSON.parse(action)
        sendRequest('entries', 'POST', data)
          .then(response => {
            setResponse(response)
            localStorage.removeItem(AFTER_LOGIN_ACTION_KEY)
          })
      }
    }
  }, [currentUser.loggedIn, setResponse])


  return null
}