import React from 'react'
import styled from 'styled-components'

import { slugify } from '../../helpers'
import { MEDIA_QUERY_WIDTH } from '../../constants'

const Container = styled.ul`
  overflow-y: auto;
  margin-left: 10px;

  @media (max-width: ${MEDIA_QUERY_WIDTH}) {
    display: none;
  }
`

const Item = styled.li`
  margin-bottom: 5px;
`

const scrollTo = (e, tag) => {
  e.preventDefault()
  const element = document.getElementsByName(slugify(tag))[0]
  element.scrollIntoView({ behavior: 'smooth' })
}

const TagList = ({ tags }) => <Container>
  {Object.keys(tags).map((tag, index) => (
    <Item key={index}>
      <a onClick={(e) => scrollTo(e, tag)} href={'#' + slugify(tag)}>
        {tag}
      </a>
    </Item>
  ))}
</Container>

export default TagList