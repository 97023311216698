import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import Sidebar from '../Sidebar'
import Header from '../Header'
import Main from '../Main'
import Overlay from '../Overlay'
import Webhook from '../Webhook'
import AfterLogin from '../AfterLogin'

import { responseSelector, userState, loadedState } from '../../atoms'
import cache from '../../data.json'
import { sendRequest } from '../../helpers'

const Container = styled.div`
  overflow: hidden;
`

const Home = () => {
  const setLoaded = useSetRecoilState(loadedState)
  const currentUser = useRecoilValue(userState)
  const setResponse = useSetRecoilState(responseSelector)

  const handleEntries = useCallback((array) => {
    setResponse(array)
  }, [setResponse])

  const fetchEntries = useCallback(() => {
    sendRequest('entries')
    .then(response => {
      handleEntries(response)
      setLoaded(true)
    })
    .catch(error => {
      console.log(error)
      setLoaded(true)
    })
  }, [handleEntries, setLoaded])

  useEffect(() => {
    if (currentUser.loggedIn) {
      fetchEntries()
    } else {
      handleEntries(cache)
    }
  }, [fetchEntries, handleEntries, currentUser.loggedIn])

  return(
    <Container>
      <Webhook />
      <AfterLogin />
      <Sidebar />
      <Header />
      <Main />
      <Overlay />
    </Container>
  )
}

export default Home
