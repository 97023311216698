
import React from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import { Form, Input } from '../Form'

import { userState } from '../../atoms'
import { useUser } from '../../hooks'
import { sendRequest } from '../../helpers'

const Actions = styled.div`
  margin: 0 15px 15px;
`

export default function Settings() {
  const currentUser = useRecoilValue(userState)
  const [logIn, logOut] = useUser()

  const handleSubmit = (data) => {
    sendRequest('users', 'PUT', data)
      .then(response => logIn(response))
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete your account?')) {
      sendRequest('users', 'DELETE')
        .then(logOut)
    }
  }

  return <>
    <Form onSubmit={handleSubmit} submitText='Update' showCancel={true} defaultValues={currentUser}>
      <Input
        type='email'
        name='email'
        label='New Email Address'
        autoComplete='email'
        rules={{ required: 'Email is required.' }}
      />
      <Input
        type='password'
        name='password'
        label='Confirm Password'
        autoComplete='current-password'
        rules={{ required: 'Password is required.' }}
      />
    </Form>
    <Actions>
      <button onClick={handleDelete}>Delete my Linkhorse Account</button>
    </Actions>
  </>
}