import React from 'react'
import styled from 'styled-components'

const BookmarkletContainer = styled.div`
  padding: 15px;

  p {
    margin-bottom: 10px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

const Link = styled.a`

`

export default function Bookmarklet() {
  const bookmarklet = `
    javascript:(function() {
      var url, title;
      url = window.location.href;
      title = document.title;
      window.location.href = "${process.env.REACT_APP_SELF_URL}?url=" + url + "&title=" + title;
    })();
  `
  return <BookmarkletContainer>
    <p>The Linkhorse bookmarklet lets you easily save whatever page you are on to your linkhorse account.</p>
    <p>To set it up, simply drag the following link to your bookmark toolbar:</p>
    <p>
      <Link
        ref={node =>
          node && node.setAttribute('href', bookmarklet)
        }>Save to Linkhorse</Link>
    </p>
  </BookmarkletContainer>
}